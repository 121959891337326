@import 'erpcore/assets/scss/main';

.main {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-content: stretch;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;

    @include breakpoint(mobile) {
        // padding-top: 92px;
    }

    &--narrow {
        > * {
            max-width: 700px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &--medium {
        > * {
            max-width: 1000px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    @include breakpoint(mobile) {
        &__footer-text {
            text-align: justify;
        }
    }
}

.invisible-fix-animation {
    display: block;
    position: fixed;
    bottom: 120px;
    right: 0;
    width: 50px;
    height: 50px;
    border: 1px solid rgba(#fff, 0.01);
    animation: invisibleanimation 5s infinite;
    pointer-events: none;
    z-index: 999999999999;
}

@keyframes invisibleanimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}